<div class="main-content"  *ngIf="!show_terms_popup">
  <div class="container">
    <div class="col-md-8 col-lg-6 ml-auto mr-auto">
      <form class="form" (ngSubmit)="onLoginByCredentials()" #loginForm="ngForm">
        <div class="card card-login card-plain">
          <div class="card-header ">
            <div class="container mb-3 w-50 mt-3">
              <img src="assets/img/sitelogo-almajdouie.png" alt="" />
            </div>
          </div>
          <div class="card-body ml-3 mr-3">  
                     <a href="#pablo" class="btn btn-primary btn-round btn-lg btn-block mb-3" (click)="onLoginByMicrosoft()"><img
                class="mr-1" style="width: 20px; height: auto;" src="assets/img/white-logo.png" alt=""> Login with
              Almajdouie</a>
            <hr>
            <p class="text-center">-Or-</p>
            <hr>
            <div class="input-group no-border form-control-lg" [ngClass]="{ 'input-group-focus': focus === true }">
              <span class="input-group-prepend">
                <div class="input-group-text">
                  <i class="now-ui-icons users_circle-08"></i>
                </div>
              </span>
              <input type="email" class="form-control" placeholder="Email..." (focus)="focus = true"
                (blur)="focus = false" [(ngModel)]="email" name="email" #emailInput="ngModel" required email />
            </div>
            <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-danger">
              <div *ngIf="emailInput.errors.required">Email is required.</div>
              <div *ngIf="emailInput.errors.email">Enter a valid email.</div>
            </div>
            <div class="input-group no-border form-control-lg" [ngClass]="{ 'input-group-focus': focus2 === true }">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="now-ui-icons objects_key-25"></i>
                </div>
              </div>
              <input type="password" placeholder="Password..." class="form-control" (focus)="focus2 = true"
                (blur)="focus2 = false" [(ngModel)]="password" name="password" #passwordInput="ngModel" required />
            </div>
            <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)" class="text-danger">
              <div *ngIf="passwordInput.errors.required">Password is required.</div>
            </div>
          </div>
          <div class="card-footer mb-3">
            <button type="submit" class="btn btn-round btn-lg btn-block mb-3" [disabled]="loginForm.invalid">Login with
              Credentials</button>

          </div>
        </div>
      </form>
    </div>
  </div>
</div>



<div class="main-content" *ngIf="show_terms_popup">
  <div class="container">
    <div class="col-md-8 col-lg-6 ml-auto mr-auto">
      <form class="form"  (ngSubmit)="onSubmitTerms()" [formGroup]="termsForm">
        <div class="card card-login card-plain">
          <div class="card-header ">
            <div class="container mb-3 w-50 mt-3">
              <img src="assets/img/sitelogo-almajdouie.png" alt="" />
            </div>
          </div>
          <div class="card-body ml-3 mr-3" >  
            <hr>
            <h5 style="color: gray;">I agree the: </h5>
              <div>
                <label class="terms-title">
                  <input type="checkbox" formControlName="termsAccepted" /> 
                   <a href="https://www.almajdouie.com/terms" target="_blank">Data Processing agreement</a>
                </label>
              </div>
            
              <div>
                <label  class="terms-title">
                  <input type="checkbox" formControlName="dataProcessingAccepted" />
                  <a href="https://www.almajdouie.com/terms" target="_blank">Employee Acknowledgment and consent</a>
                </label>
              </div>
              <hr>
            
              <div>
                <button type="submit" class="btn btn-primary btn-round btn-lg btn-block mb-3" [disabled]="termsForm.invalid">Submit</button>
              </div>
          
          </div>
          
        </div>
      </form>
    </div>
  </div>
</div>