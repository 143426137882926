import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutes } from "./app.routing";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { GraphQLModule } from "./graphql.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { GraphqlInterceptor } from "./graphql-interceptor.service";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgbModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    GraphQLModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot("G-E3E7TKWYVQ"),
    NgMultiSelectDropDownModule.forRoot(),
    EditorModule,
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphqlInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas : [NO_ERRORS_SCHEMA]
})
export class AppModule {}
