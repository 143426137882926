<footer class="footer">
  <div class="container-fluid">

    <div  id="copyright">
      Copyright &copy; {{ test | date: "yyyy" }} Admin Panel all rights reserved. | Developed with <i
        class="ni ni-favourite-28"></i> by <a href="mailto:webservices@almajdouie.com" style="color: rgba(0, 128, 255, 0.901);">Almajdouie Web Team </a>.

    </div>
  </div>
</footer>
