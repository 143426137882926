<div class="main-content" *ngIf="!show_terms_popup">
  <div class="container">
    <div class="col-md-8 col-lg-6 ml-auto mr-auto">
      <form class="form" (ngSubmit)="onLoginByCredentials()" #loginForm="ngForm">
        <div class="card card-login card-plain">
          <div class="card-header ">
            <div class="container mb-3 w-50 mt-3">
              <img src="assets/img/sitelogo-almajdouie.png" alt="" />
            </div>
          </div>
          <div class="card-body ml-3 mr-3">
            <a href="#pablo" class="btn btn-primary btn-round btn-lg btn-block mb-3" (click)="onLoginByMicrosoft()"><img
                class="mr-1" style="width: 20px; height: auto;" src="assets/img/white-logo.png" alt=""> Login with
              Almajdouie</a>
            <hr>
            <p class="text-center" style="color: black !important;font-weight: bold;">-Or-</p>
            <hr>
            <div class="input-group no-border form-control-lg" [ngClass]="{ 'input-group-focus': focus === true }">
              <span class="input-group-prepend">
                <div class="input-group-text">
                  <i class="now-ui-icons users_circle-08"></i>
                </div>
              </span>
              <input type="email" class="form-control" placeholder="Email..." (focus)="focus = true"
                (blur)="focus = false" [(ngModel)]="email" name="email" #emailInput="ngModel" required email />
            </div>
            <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="text-danger">
              <div *ngIf="emailInput.errors.required">Email is required.</div>
              <div *ngIf="emailInput.errors.email">Enter a valid email.</div>
            </div>
            <div class="input-group no-border form-control-lg" [ngClass]="{ 'input-group-focus': focus2 === true }">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <i class="now-ui-icons objects_key-25"></i>
                </div>
              </div>
              <input type="password" placeholder="Password..." class="form-control" (focus)="focus2 = true"
                (blur)="focus2 = false" [(ngModel)]="password" name="password" #passwordInput="ngModel" required />
            </div>
            <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched)" class="text-danger">
              <div *ngIf="passwordInput.errors.required">Password is required.</div>
            </div>
          </div>
          <div class="card-footer mb-3">
            <button type="submit" class="btn btn-round btn-lg btn-block mb-3" [disabled]="loginForm.invalid">Login with
              Credentials</button>

          </div>
        </div>
      </form>
    </div>
  </div>
</div>



<div class="main-content" *ngIf="show_terms_popup">
  <div class="container">
    <div class="col-md-8 col-lg-10 ml-auto mr-auto">
      <div class="card card-login card-plain">
        <div class="card-header ">
          <div class="container mb-3 w-50 mt-3">
            <img src="assets/img/sitelogo-almajdouie.png" alt="" />
          </div>
        </div>

        <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; text-align: center; width: 100%;">
          <!-- Language Selection Dropdown -->
          <label for="language-select" style="margin-bottom: 5px;">Select Language:</label>
          <select style="width: 100px;" class="form-control form-control-sm" id="language-select" [(ngModel)]="selectedLanguage">
            <option value="en">English</option>
            <option value="ar">Arabic</option>
          </select>
        </div>
        
        <form class="form" (ngSubmit)="onSubmitTerms()" [formGroup]="termsForm">
          <div class="card-body ml-3 mr-3">
            <hr>
            <p class="text-center">
              __('Please review and sign the following agreements:') /
              __('يرجى قراءة الاتفاقيات التالية وتوقيعها:')
            </p>



            <div style=" height: 300px; overflow: scroll;" *ngIf="selectedLanguage === 'en'">

              <h3>{{ agreementDataEn.acknowledgment_title??''}}</h3>
              <div class="terms" [innerHTML]="agreementDataEn.acknowledgment_body | safeHtml" style="direction: inherit;"></div>
              <h3>{{ agreementDataEn.dpa_0 }}</h3>
              <div class="terms" [innerHTML]="agreementDataEn.dpa_1 | safeHtml" style="direction: inherit;"></div>
              <div class="terms" [innerHTML]="agreementDataEn.dpa_2 | safeHtml" style="direction: inherit;"></div>
              <div class="terms">IN WITNESS WHEREOF, this Agreement is entered into with effect from the date first set out below.</div>

              <div>
                <label class="terms-title">
                  <input type="checkbox" formControlName="termsAccepted" />
                  <a href="https://www.almajdouie.com/terms" target="_blank">{{'I have read and agree to' +' '+agreementDataEn.acknowledgment_title}}</a>
                </label>
              </div>

              <div>
                <label class="terms-title">
                  <input type="checkbox" formControlName="dataProcessingAccepted" />
                  <a href="https://www.almajdouie.com/terms" target="_blank">{{'I have read and agree to' +' '+agreementDataEn.dpa_0}}</a>
                </label>
              </div>
            </div>


            <div style="direction: rtl !important; height: 300px; overflow: scroll; text-align: right;"
              *ngIf="selectedLanguage === 'ar'">
              <h3>{{ agreementData.acknowledgment_title}}</h3>
              <div class="terms"[innerHTML]="agreementData.acknowledgment_body | safeHtml" style="direction: inherit;"></div>
             
              <h3>{{ agreementData.dpa_0}}</h3>
              <div class="terms" [innerHTML]="agreementData.dpa_1 | safeHtml" style="direction: inherit;"></div>
              <div class="terms"[innerHTML]="agreementData.dpa_2 | safeHtml" style="direction: inherit;"></div>
              <div class="terms">تم التوقيع من قبل الطرفين في التاريخ المذكور أدناه</div>

              <div>
                <label class="terms-title">
                  <input type="checkbox" formControlName="termsAccepted" />
                  <a href="https://www.almajdouie.com/terms" target="_blank">{{' لقد قرأت وأوافق على' +' '+agreementData.acknowledgment_title}}</a>
                </label>
              </div>

              <div>
                <label class="terms-title">
                  <input type="checkbox" formControlName="dataProcessingAccepted" />
                  <a href="https://www.almajdouie.com/terms" target="_blank">{{' لقد قرأت وأوافق على' +' '+agreementData.dpa_0}}</a>
                </label>
              </div>
            </div>

            
            <hr>
          
            <h4 style="text-align: center;">Signature توقيع</h4>
            <div class="signature">
              
            <signature-pad  #signaturePadRef [options]="signaturePadOptions"  (drawEnd)="onSignatureEnd($event)"></signature-pad>

            <br>
            <button type="button" class="btn btn-primary" (click)="clearPad()">Clear</button>
            
               <!-- <div *ngIf="termsForm.get('signaturePad').invalid && termsForm.get('signaturePad').touched" class="error">
                  Signature is required.
                </div>
                -->
            </div>
            <div>
              <button type="submit" class="btn btn-primary btn-round btn-lg btn-block mb-3"
                [disabled]="termsForm.invalid">Submit</button>
            </div>

          </div>
        </form>
      </div>

    </div>
  </div>
</div>