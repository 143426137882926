import { Component, OnInit } from "@angular/core";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { AuthService } from "./auth.service";
import { UserService } from "./user.service";
import { User } from "./user";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "Admin mmc";
  user!: User;
  userData$;
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {
    this.userData$ = this.userService.userData$;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  async ngOnInit() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const sessionState = urlParams.get("session_state");
    const refreshToken = urlParams.get("refresh_token");
    if (this.authService.isAuthenticated())
    {
      const urlSplit = window.location.href.split("/");
      const activeRoute = urlSplit[urlSplit.length - 1];
      if (activeRoute == 'login') {
        this.router.navigate(["/dashboard"]);
      }
    }
    else if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
      await this.authService.refreshToken();
      this.router.navigate(["/dashboard"]);
    } else {
      if (code || sessionState) {
        const navigationExtras = {
          queryParams: { code: code, session_state: sessionState },
        };
        this.router.navigate(["/login"], navigationExtras);
      }
      this.authService.handleAuthentication();
      this.userData$.subscribe((userData) => {
        this.user = userData;
      });
    }
  }
}
