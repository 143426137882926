import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { LoginComponent } from "./pages/auth/login/login.component";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "login",
        component: LoginComponent,
        pathMatch: "full",
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (x) => x.DashboardModule
          ),
      },
      {
        path: "components",
        loadChildren: () =>
          import("./pages/components/components.module").then(
            (x) => x.ComponentsPageModule
          ),
      },
      {
        path: "data-bank",
        loadChildren: () =>
          import("./pages/data-bank/data-bank.module").then(
            (x) => x.DataBankModule
          ),
      },
      {
        path: "marketing",
        loadChildren: () =>
          import("./pages/marketing/marketing.module").then(
            (x) => x.MarketingModule
          ),
      },
      {
        path: "sales",
        loadChildren: () =>
          import("./pages/sales/sales.module").then(
            (x) => x.SalesModule
          ),
      },
    ],
  },
];
