import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { UserService } from "./user.service";
import { User } from "./user";
import jwt_decode from "jwt-decode";
import { environment } from "src/environments/environment";
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";

const SIGN_IN_USER = gql`
  query login($email: String!, $password: String!, $app_name: String!) {
    login(email: $email, password: $password, app_name: $app_name)
  }
`;

const SIGN_IN_USER_BY_ACTIVE_DIRECTORY = gql`
  query loginActiveDirectory($code: String!, $app_name: String!) {
    loginActiveDirectory(code: $code, app_name: $app_name)
  }
`;

const SET_USER_ACCEPT_TERMS = gql`
  query setAcceptPDPLTerms($uid: String!,$signature: String!) {
    setAcceptPDPLTerms(uid: $uid,signature: $signature)
  }
`;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private refreshTokenEndpoint =
    this.apiUrl + "/user-access/auth/refresh-token";

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService,
    private apollo: Apollo
  ) {}
  private tokenRefreshTimer: any;
  signIn(email: string, password: string, app_name: string): Observable<any> {
    return this.apollo.use("userAccess").mutate({
      mutation: SIGN_IN_USER,
      variables: { email: email, password: password, app_name: app_name },
      fetchPolicy: "network-only",
    });
  }

  signInByActiveDirectory(code: string, app_name: string): Observable<any> {
    return this.apollo.use("userAccess").mutate({
      mutation: SIGN_IN_USER_BY_ACTIVE_DIRECTORY,
      variables: { code: code, app_name: app_name },
      fetchPolicy: "network-only",
    });
  }
  isAuthenticated(): boolean {
    // Check if the user is authenticated based on the presence of a refresh token in local storage
    const refreshToken = localStorage.getItem("refreshToken");
    return refreshToken !== null;
  }

  setAcceptPDPLTerms(uid: string,signature:string): Observable<any> {
    return this.apollo.use("userAccess").mutate({
      mutation: SET_USER_ACCEPT_TERMS,
      variables: { uid: uid,signature:signature},
      fetchPolicy: "network-only",
    });
  }

  getAccessToken(): string | null {
    // Retrieve the access token from local storage
    const accessToken = localStorage.getItem("accessToken");
    try {
      const decodedToken = jwt_decode(accessToken);
      const expirationDate = new Date(decodedToken["exp"] * 1000);
      if (expirationDate < new Date()) localStorage.removeItem("accessToken");
    } catch (Error) {
      localStorage.removeItem("accessToken");
      return null;
    }
    return localStorage.getItem("accessToken");
  }

  setTokens(accessToken: string, refreshToken: string): void {
    // Store the access token and refresh token in local storage
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }

  clearTokens(): void {
    // Remove the access token and refresh token from local storage
    this.userService.clearUserData();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.reload()
  }

  async refreshToken(): Promise<void> {
    const refreshToken = localStorage.getItem("refreshToken") || "";
    if (refreshToken) {
      const refreshTokenPayload = {
        refresh_token: refreshToken,
        app_code: "admin-panel",
      };

      this.http.post(this.refreshTokenEndpoint, refreshTokenPayload).subscribe(
        (response: any) => {
          const newAccessToken = response.access_token;
          if (newAccessToken) {
            // Update the access token in local storage
            localStorage.setItem("accessToken", newAccessToken);
            const userData = new User(newAccessToken);
            this.userService.setUserData(userData); // retrieve user data from backend or local storage
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
              const decodedToken = jwt_decode(accessToken);
              const expirationDate = new Date(decodedToken["exp"] * 1000);
              const timeUntilExpiration = expirationDate.getTime() - Date.now();
              const refreshInterval = timeUntilExpiration - 10000; // Refresh 10 seconds before token expiration
              this.initiateRefreshTimer(refreshInterval);
            }
          } else {
            this.clearTokens();
          }
        },
        (error: any) => {
          console.log(error);
          this.clearTokens();
          // Handle the error case when the refresh token API call fails
          // You may want to log an error or redirect to the authentication page
        }
      );
    } else {
      this.clearTokens();
      // Handle the case when the refresh token is missing from local storage
      // You may want to log an error or redirect to the authentication page
    }
  }
  private initiateRefreshTimer(interval: number): void {
    console.log("initiateRefreshTimer");
    // Clear the previous timer to prevent multiple timers running simultaneously
    if (this.tokenRefreshTimer) {
      clearTimeout(this.tokenRefreshTimer);
    }

    // Set the new timer for token refresh
    this.tokenRefreshTimer = setTimeout(async () => {
      // Perform the token refresh
      await this.refreshToken();
    }, interval);
  }
  async handleAuthentication() {
    if (!this.isAuthenticated()) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const queryParams = this.router.parseUrl(this.router.url).queryParams;
          this.router.navigate(["/login"], { queryParams: queryParams });
        }
      });
    } else if (!this.getAccessToken()) {
      // If the access token is missing, attempt to refresh it
      await this.refreshToken();
    } else {
      // Retrieve user data from backend or local storage and set it in the UserService

      const userData = new User(this.getAccessToken());
      this.userService.setUserData(userData); 
      // retrieve user data from backend or local storage
    }
  }
}
