import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError, of } from "rxjs";
import { AuthService } from "src/app/auth.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  focus = false;
  focus2 = false;
  email: string;
  password: string;
  app_code: string;
  redirect_url: string;
  show_terms_popup: boolean = false;
  termsForm: FormGroup;
  user_uid: string = null;
  refresh_token: string = null;
  access_token: string = null;
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const $page = document.getElementsByClassName(
      "full-page"
    )[0] as HTMLElement;
    const image_container = document.createElement("div");
    image_container.classList.add("full-page-background");
    image_container.style.backgroundImage =
      "url(assets/img/almajdouie-tower3.png)";
    $page.appendChild(image_container);
    this.termsForm = this.fb.group({
      termsAccepted: [false, Validators.requiredTrue], // Validation: must be true
      dataProcessingAccepted: [false, Validators.requiredTrue], // Validation: must be true
    });
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (
        params.code &&
        localStorage.getItem("app_code") &&
        localStorage.getItem("redirect_url")
      ) {
        this.onRedirectFromMicrosoft(
          params.code,
          localStorage.getItem("app_code"),
          localStorage.getItem("redirect_url")
        );
        return;
      }
     
      localStorage.removeItem("app_code");
      this.app_code = params["app_code"] || "admin-panel";
      this.redirect_url = params["redirect_url"] || environment.panelUrl;
     
      if (
        this.app_code == "admin-panel" &&
        this.redirect_url == environment.panelUrl
      ) {
        if (this.authService.isAuthenticated()) {
          this.authService.handleAuthentication();
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  onLoginByCredentials() {
    this.email = this.email.replace(/\s+/g, ""); // Remove spaces
    if (this.validateForm()) {
      Swal.showLoading();
      this.authService
        .signIn(this.email, this.password, this.app_code)
        .pipe(
          catchError((error) => {
            Swal.close();
            this.showNotificationMessage(error.message);
            return of(error);
          })
        )
        .subscribe(({ data }: any) => {
          Swal.close();
          if (data != null) {
            var result = JSON.parse(data.login);
            if (!result.user.is_terms_accepted) {
              this.user_uid = result.user.uid;
              this.show_terms_popup = true;
              this.refresh_token = result.refresh_token;
              this.access_token = result.access_token;
            } else {
              window.location.href =this.redirect_url +
                "?refresh_token=" +
                result.refresh_token;
            }
          }
        });
    }
  }
  onLoginByMicrosoft() {
    localStorage.setItem("app_code", this.app_code);
    localStorage.setItem("redirect_url", this.redirect_url);
    window.location.href =
      "https://login.microsoftonline.com/48b0ba82-dbb5-494a-86b1-ac3c5f0f0bca/oauth2/v2.0/authorize?client_id=f434f162-d299-482b-8918-097276435feb&scope=user.read&response_type=code&response_mode=query&redirect_uri=" +
      environment.authUrl;
  }
  onRedirectFromMicrosoft(code, app_code, redirect_url) {
    Swal.showLoading();
    this.app_code = app_code;
    this.redirect_url = redirect_url;
    this.authService
      .signInByActiveDirectory(code, app_code)
      .pipe(
        catchError((errors) => {
          Swal.close();
          this.showNotificationMessage(errors.toString().split(":")[1]);
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { redirect_url, app_code },
          });
          return of(errors);
        })
      )
      .subscribe(({ data }: any) => {
        Swal.close();
        if (data != null) {
          var result = JSON.parse(data.loginActiveDirectory);
          if (!result.user.is_terms_accepted) {
            this.user_uid = result.user.uid;
            this.show_terms_popup = true;
            this.refresh_token = result.refresh_token;
            this.access_token = result.access_token;
          } else {
            window.location.href = this.redirect_url + "?refresh_token=" + result.refresh_token;
          }
        }
      });
  }
  validateForm() {
    if (!this.email || !this.password) {
      this.showNotificationMessage("Both email and password are required.");
      return false;
    }
    if (!this.validateEmail(this.email)) {
      this.showNotificationMessage("Enter a valid email address.");
      return false;
    }
    return true;
  }

  validateEmail(email: string) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  }
  showNotificationMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>',
      message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right",
      }
    );
  }

  onSubmitTerms() {
    if (this.termsForm.valid) {
      // You can process the form submission here
      //console.log("Form Submitted", this.termsForm.value);
      localStorage.setItem('accessToken',this.access_token);
      Swal.showLoading();
      this.authService
        .setAcceptPDPLTerms(this.user_uid)
        .pipe(
          catchError((errors) => {
            Swal.close();
            this.showNotificationMessage(errors.toString().split(":")[1]);
            return of(errors);
          })
        )
        .subscribe(({ data }: any) => {
          Swal.close();
          if (data != null) {
            window.location.href =
              "" + this.redirect_url + "?refresh_token=" + this.refresh_token;
          }
        });
    }
  }
}
